<template>
    <div class="like-button">
        <img
            :src="require(`@assets/img/icons/heart-${ memod.is_liked ? 'full' : 'empty' }.svg`)"
            class="like"
            alt="Like"
            @click="likeMemo"
        >
        <span v-if="memod.total_likes">{{ memod.total_likes }}</span>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "LikeButton",
    props: {
        memod: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "User/isLoggedIn"
        })
    },
    methods: {
        likeMemo() {
            if (!this.isLoggedIn) {
                this.$modal.show("auth-modal", { allowClose: true, showSignIn: true });
            } else {
                const isLiked = !this.memod.is_liked;
                const totalLikes = isLiked ? this.memod.total_likes + 1 : this.memod.total_likes - 1;
                this.$emit("toggled-like", { isLiked, totalLikes });
                this.$amplitude.logEvent(isLiked ? "Like" : "Dislike", {
                    entity_id: this.memod.id,
                    event_name: isLiked ? "Like" : "Dislike",
                    title: this.memod.title
                });
                axios.post(`/feeds/${this.memod.id}/like`).then(() => {
                }).catch((error) => {
                    console.log(error.response.data.errors.message);
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.like-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;

    span {
        display: block;
        position: absolute;
        left: 30px;
        display: flex;
        align-items: center;
    }
}
</style>
